import axios from "axios";
import {renderurl} from "../CarSearch/components/globalvar";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

export default function LogMeOut(props) {

    console.log('logmeout');
   //  const navigate = useNavigate();
   //
   // navigate("/");

}

