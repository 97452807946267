import React from "react";
import './Stylesheets/header/header.css';

import './Stylesheets/layout/centre.css';
import './Stylesheets/centre/centrestyles.css';

export default function LongBlueLine() {
    return (
        <div className="container">
            <div className="longblueline">
            </div>
        </div>
    )
}
