

export default function StripeImg() {

    return (

        <div>
            <p>The first month is free, after-which it is £0.99/ month and you can canel at anytime.</p>
            <p>Payments are managed by Stripe and can be cancelled anytime.</p>
        </div>


    )

}